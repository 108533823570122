var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading.fullscreen.lock",value:(_vm.loading),expression:"loading",modifiers:{"fullscreen":true,"lock":true}}],attrs:{"id":"project-management"}},[_c('div',{staticClass:"header bg_white"},[_c('h1',{staticClass:"titH"},[_vm._v(" "+_vm._s(_vm.$t("projectManagement.projectsList"))+" ")]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"recycle-btn mr10",on:{"click":_vm.jumpRecycle}},[_c('i',{staticClass:"iconfont icon-huishouzhan"}),_c('span',[_vm._v(" "+_vm._s(_vm.$t("projectManagement.recycle")))])]),(_vm.rightShow('CrmTask@projectInfo'))?_c('div',{staticClass:"add-btn",on:{"click":function($event){return _vm.addProject()}}},[_vm._v(" "+_vm._s(_vm.$t("projectManagement.createProject"))+" ")]):_vm._e()])]),_c('div',{staticClass:"project-box"},[_c('h1',{staticClass:"project-tit flex-between"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("projectManagement.participateIn"))+" ")]),_c('span',{staticClass:"yellow-text toggle-span hand",on:{"click":function($event){return _vm.toggleProject(0)}}},[_c('span',[_vm._v(_vm._s(_vm.showProject[0] ? _vm.$t("projectManagement.hide") : _vm.$t("projectManagement.unfold")))]),_c('i',{class:[
            _vm.showProject[0] ? 'el-icon-arrow-right' : 'el-icon-arrow-down' ]})])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showProject[0]),expression:"showProject[0]"}],staticClass:"project-inner_box"},[(_vm.publicList.length)?_vm._l((_vm.publicList),function(item,index){return _c('div',{key:index,staticClass:"project-item"},[_c('div',{staticClass:"hand flex-a-center",on:{"click":function($event){return _vm.viewProject(item.id)}}},[(item.project_photo !== '')?_c('img',{staticClass:"project-img",attrs:{"src":'api/Common/viewCrmTaskImg' + '?path=' + item.project_photo}}):_c('span',{staticClass:"project-icon"},[_c('i',{staticClass:"iconfont icon-xiangmu1"})]),_c('div',{staticClass:"flex-between task-status-hand"},[_c('div',{staticClass:"flex-a-b"},[(Date.parse(item.want_end_time) < new Date().getTime())?[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":_vm.$t('projectManagement.overdue'),"placement":"top-start"}},[_c('span',{staticClass:"red-text project-name ellipsis",attrs:{"title":item.project_name}},[_vm._v(" "+_vm._s(item.project_name)+" ")])])]:_c('span',{staticClass:"project-name ellipsis",attrs:{"title":item.project_name}},[_vm._v(" "+_vm._s(item.project_name)+" ")]),_c('span',{class:_vm.getStatusClass(item.status)},[_vm._v(" -"+_vm._s(_vm.formateStatus(item.status))+" ")])],2),_c('div',[_c('el-popover',{attrs:{"placement":"right","width":"400","trigger":"hover"}},[_c('el-cascader-panel',{staticClass:"user-cascader",attrs:{"options":item.userClass},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var node = ref.node;
          var data = ref.data;
return [_c('span',[_vm._v(_vm._s(data.label))]),(!node.isLeaf)?_c('span',[_vm._v(" "+_vm._s(data.children.length)+" ")]):_vm._e()]}}],null,true)}),_c('div',{attrs:{"slot":"reference"},slot:"reference"},[_c('i',{staticClass:"iconfont icon-qunzu"}),_c('span',{staticClass:"project-num"},[_vm._v(_vm._s(item.num_total))])])],1)],1)])]),_c('div',{staticClass:"project-info multi-line",on:{"click":function($event){return _vm.viewProject(item.id)}}},[_vm._v(" "+_vm._s(item.info)+" ")]),_c('div',{staticClass:"mb16 flex-between time-box"},[_c('div',[_c('i',{staticClass:"el-icon-date"}),_c('span',[_vm._v(" "+_vm._s(item.want_start_time ? _vm.getFormateTime(item.want_end_time) : _vm.$t("projectManagement.unfinished"))+" ")])]),_c('div',[_c('i',{staticClass:"el-icon-time"}),_c('span',{},[_vm._v(" "+_vm._s(item.want_end_time ? _vm.getFormateTime(item.want_end_time) : _vm.$t("projectManagement.unfinished")))])])]),_c('div',{staticClass:"task-total flex-between"},[_c('div',[_c('span',{staticClass:"light-tit"},[_vm._v(" "+_vm._s(_vm.$t("projectManagement.totalTasks"))+" ")]),(item.link_task_total_num > 0)?_c('span',{staticClass:"black-text",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.go(item, 1)}}},[_vm._v(" "+_vm._s(item.link_task_total_num)+" ")]):_c('span',{staticClass:"black-text"},[_vm._v(" "+_vm._s(item.link_task_total_num)+" ")])]),_c('div',[_c('span',{staticClass:"light-tit"},[_vm._v(" "+_vm._s(_vm.$t("projectManagement.unfinished2"))+"： ")]),(
                  item.link_task_total_num - item.link_task_completed_num > 0
                )?_c('span',{staticClass:"blue-text",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.go(item, 2)}}},[_vm._v(" "+_vm._s(item.link_task_total_num - item.link_task_completed_num)+" ")]):_c('span',{staticClass:"blue-text"},[_vm._v(" "+_vm._s(item.link_task_total_num - item.link_task_completed_num)+" ")])])]),_c('div',{staticClass:"tag-box"},_vm._l((item.tags_name),function(tagItem,index){return _c('span',{key:index,style:({ backgroundColor: tagItem.value })},[_vm._v(" "+_vm._s(tagItem.name)+" ")])}),0),_c('div',{staticClass:"handel-box"},[(_vm.rightShow('CrmTask@joinProject'))?_c('zc-icon-btn',{attrs:{"content":_vm.$t('projectManagement.participationProjects'),"btn_type":"warning","btn_class":"form_button","icon":"icon-ruku"},on:{"click":function($event){return _vm.joinProject(item.id)}}}):_vm._e()],1),(item && item.link_task_total_num !== 0)?_c('div',{staticClass:"progress-box"},[(item.link_task_completed_num !== 0)?_vm._l((Math.round(
                  (item.link_task_completed_num / item.link_task_total_num) *
                    5
                )),function(doneItem,index){return _c('span',{key:index,staticClass:"done-span"})}):_vm._e(),(
                item.link_task_total_num - item.link_task_completed_num !== 0
              )?_vm._l((Math.round(
                  (item.link_task_total_num - item.link_task_completed_num) /
                    item.link_task_total_num
                ) * 5),function(undoItem,index){return _c('span',{key:index,staticClass:"undone-span"})}):_vm._e()],2):_vm._e()])}):_c('p',{staticClass:"no-data center"},[_vm._v(" "+_vm._s(_vm.$t("projectManagement.noData"))+" ")])],2)]),_c('div',{staticClass:"project-box"},[_c('h1',{staticClass:"project-tit flex-between"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("projectManagement.unfinishedProjects"))+" ")]),_c('span',{staticClass:"yellow-text toggle-span hand",on:{"click":function($event){return _vm.toggleProject(1)}}},[_c('span',[_vm._v(_vm._s(_vm.showProject[1] ? _vm.$t("projectManagement.hide") : _vm.$t("projectManagement.unfold")))]),_c('i',{class:[
            _vm.showProject[1] ? 'el-icon-arrow-right' : 'el-icon-arrow-down' ]})])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showProject[1]),expression:"showProject[1]"}],staticClass:"project-inner_box"},[(_vm.myJoinList.length)?_vm._l((_vm.myJoinList),function(item,index){return _c('div',{key:index,staticClass:"project-item"},[_c('div',{staticClass:"hand flex-a-center",on:{"click":function($event){return _vm.viewProject(item.id)}}},[(item.project_photo !== '')?_c('img',{staticClass:"project-img",attrs:{"src":'api/Common/viewCrmTaskImg' + '?path=' + item.project_photo}}):_c('span',{staticClass:"project-icon"},[_c('i',{staticClass:"iconfont icon-xiangmu1"})]),_c('div',{staticClass:"flex-between task-status-hand"},[_c('div',{staticClass:"flex-a-b"},[(Date.parse(item.want_end_time) < new Date().getTime())?[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":_vm.$t('projectManagement.overdue'),"placement":"top-start"}},[_c('span',{staticClass:"red-text project-name ellipsis",attrs:{"title":item.project_name}},[_vm._v(" "+_vm._s(item.project_name)+" ")])])]:_c('span',{staticClass:"project-name ellipsis",attrs:{"title":item.project_name}},[_vm._v(" "+_vm._s(item.project_name)+" ")]),_c('span',{class:_vm.getStatusClass(item.status)},[_vm._v(" -"+_vm._s(_vm.formateStatus(item.status))+" ")])],2),_c('div',[_c('el-popover',{attrs:{"placement":"right","width":"400","trigger":"hover"}},[_c('el-cascader-panel',{staticClass:"user-cascader",attrs:{"options":item.userClass},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var node = ref.node;
          var data = ref.data;
return [_c('span',[_vm._v(_vm._s(data.label))]),(!node.isLeaf)?_c('span',[_vm._v(" "+_vm._s(data.children.length)+" ")]):_vm._e()]}}],null,true)}),_c('div',{attrs:{"slot":"reference"},slot:"reference"},[_c('i',{staticClass:"iconfont icon-qunzu"}),_c('span',{staticClass:"project-num"},[_vm._v(_vm._s(item.num_total))])])],1)],1)])]),_c('div',{staticClass:"project-info multi-line hand",on:{"click":function($event){return _vm.viewProject(item.id)}}},[_vm._v(" "+_vm._s(item.info)+" ")]),_c('div',{staticClass:"mb16 flex-between time-box"},[_c('div',[_c('i',{staticClass:"el-icon-date"}),_c('span',[_vm._v(" "+_vm._s(item.want_start_time ? _vm.getFormateTime(item.want_start_time) : _vm.$t("projectManagement.unfinished"))+" ")])]),_c('div',[_c('i',{staticClass:"el-icon-time"}),_c('span',{},[_vm._v(" "+_vm._s(item.want_end_time ? _vm.getFormateTime(item.want_end_time) : _vm.$t("projectManagement.unfinished")))])])]),_c('div',{staticClass:"task-total flex-between"},[_c('div',[_c('span',{staticClass:"light-tit"},[_vm._v(" "+_vm._s(_vm.$t("projectManagement.totalTasks"))+" ")]),(item.link_task_total_num > 0)?_c('span',{staticClass:"black-text",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.go(item, 1)}}},[_vm._v(" "+_vm._s(item.link_task_total_num)+" ")]):_c('span',{staticClass:"black-text"},[_vm._v(" "+_vm._s(item.link_task_total_num)+" ")])]),_c('div',[_c('span',{staticClass:"light-tit"},[_vm._v(" "+_vm._s(_vm.$t("projectManagement.unfinished2"))+"： ")]),(
                  item.link_task_total_num - item.link_task_completed_num > 0
                )?_c('span',{staticClass:"blue-text",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.go(item, 2)}}},[_vm._v(" "+_vm._s(item.link_task_total_num - item.link_task_completed_num)+" ")]):_c('span',{staticClass:"blue-text"},[_vm._v(" "+_vm._s(item.link_task_total_num - item.link_task_completed_num)+" ")])])]),_c('div',{staticClass:"tag-box"},_vm._l((item.tags_name),function(tagItem,index){return _c('span',{key:index,style:({ backgroundColor: tagItem.value })},[_vm._v(" "+_vm._s(tagItem.name)+" ")])}),0),_c('div',{staticClass:"handel-box"},[(
                _vm.rightShow('CrmTask@startProject') &&
                item.status === 2 &&
                item.permissions > 1
              )?_c('div',{staticClass:"btn-w btn-start"},[_c('zc-icon-btn',{attrs:{"content":_vm.$t('projectManagement.startProject'),"btn_type":"success","btn_class":"form_button mb10","icon":"icon-kaishixiangmu"},on:{"click":function($event){return _vm.startProject(item)}}})],1):_vm._e(),(
                _vm.rightShow('CrmTask@finishProject') &&
                item.status == 3 &&
                item.permissions > 1
              )?_c('div',{staticClass:"btn-w btn-finish"},[_c('zc-icon-btn',{attrs:{"content":_vm.$t('projectManagement.finishProject'),"btn_type":"primary","btn_class":"form_button mb10","icon":"icon-wanchengxiangmu"},on:{"click":function($event){return _vm.finishProject(item.id)}}})],1):_vm._e(),(
                _vm.rightShow('CrmTask@inviteUserToProject') &&
                item.checkInviteRole === true
              )?_c('div',{staticClass:"btn-w btn-invite"},[_c('zc-icon-btn',{attrs:{"content":_vm.$t('projectManagement.inviteMembers'),"btn_type":"primary","btn_class":"form_button mb10 gray-btn","icon":"icon-xinzengkehu"},on:{"click":function($event){return _vm.inviteUserToProject(item.id)}}})],1):_vm._e(),_c('div',{staticClass:"btn-w btn-edit"},[(
                  _vm.rightShow('CrmTask@projectInfoPut') && item.permissions > 1
                )?_c('zc-icon-btn',{attrs:{"content":_vm.$t('edit'),"btn_type":"warning","btn_class":"form_button gray-btn","icon":"icon-bianji"},on:{"click":function($event){return _vm.editProject(item.id)}}}):_vm._e()],1),_c('div',{staticClass:"btn-w btn-del"},[(
                  _vm.rightShow('CrmTask@deleteProject') && item.permissions > 1
                )?_c('zc-icon-btn',{attrs:{"content":_vm.$t('delete'),"btn_type":"danger","btn_class":"form_button","icon":"icon-qingkong"},on:{"click":function($event){return _vm.delProject(item.id)}}}):_vm._e()],1)]),(item && item.link_task_total_num !== 0)?_c('div',{staticClass:"progress-box"},[(item.link_task_completed_num !== 0)?_vm._l((Math.round(
                  (item.link_task_completed_num / item.link_task_total_num) *
                    5
                )),function(doneItem,index){return _c('span',{key:index,staticClass:"done-span"})}):_vm._e(),(
                item.link_task_total_num - item.link_task_completed_num !== 0
              )?_vm._l((Math.round(
                  (item.link_task_total_num - item.link_task_completed_num) /
                    item.link_task_total_num
                ) * 5),function(undoItem,index){return _c('span',{key:index,staticClass:"undone-span"})}):_vm._e()],2):_vm._e()])}):_c('p',{staticClass:"no-data center"},[_vm._v(" "+_vm._s(_vm.$t("projectManagement.noData"))+" ")])],2)]),_c('div',{staticClass:"project-box"},[_c('h1',{staticClass:"project-tit flex-between"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("projectManagement.completedProjects"))+" ")]),_c('span',{staticClass:"yellow-text toggle-span hand",on:{"click":function($event){return _vm.toggleProject(2)}}},[_c('span',[_vm._v(_vm._s(_vm.showProject[2] ? _vm.$t("projectManagement.hide") : _vm.$t("projectManagement.unfold")))]),_c('i',{class:[
            _vm.showProject[2] ? 'el-icon-arrow-right' : 'el-icon-arrow-down' ]})])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showProject[2]),expression:"showProject[2]"}],staticClass:"project-inner_box"},[(_vm.finishedList.length)?_vm._l((_vm.finishedList),function(item,index){return _c('div',{key:index,staticClass:"project-item"},[_c('div',{staticClass:"hand flex-a-center",on:{"click":function($event){return _vm.viewProject(item.id)}}},[(item.project_photo !== '')?_c('img',{staticClass:"project-img",attrs:{"src":'api/Common/viewCrmTaskImg' + '?path=' + item.project_photo}}):_c('span',{staticClass:"project-icon"},[_c('i',{staticClass:"iconfont icon-xiangmu1"})]),_c('div',{staticClass:"flex-between task-status-hand"},[_c('div',{staticClass:"flex-a-b"},[_c('span',{staticClass:"project-name ellipsis",attrs:{"title":item.project_name}},[_vm._v(" "+_vm._s(item.project_name)+" ")]),_c('span',{class:_vm.getStatusClass(item.status)},[_vm._v(" -"+_vm._s(_vm.formateStatus(item.status))+" ")])]),_c('div',[_c('el-popover',{attrs:{"placement":"right","width":"400","trigger":"hover"}},[_c('el-cascader-panel',{staticClass:"user-cascader",attrs:{"options":item.userClass},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var node = ref.node;
          var data = ref.data;
return [_c('span',[_vm._v(_vm._s(data.label))]),(!node.isLeaf)?_c('span',[_vm._v(" "+_vm._s(data.children.length)+" ")]):_vm._e()]}}],null,true)}),_c('div',{attrs:{"slot":"reference"},slot:"reference"},[_c('i',{staticClass:"iconfont icon-qunzu"}),_c('span',{staticClass:"project-num"},[_vm._v(_vm._s(item.num_total))])])],1)],1)])]),_c('div',{staticClass:"project-info multi-line hand",on:{"click":function($event){return _vm.viewProject(item.id)}}},[_vm._v(" "+_vm._s(item.info)+" ")]),_c('div',{staticClass:"mb16 flex-between time-box"},[_c('div',[_c('i',{staticClass:"el-icon-date"}),_c('span',[_vm._v(" "+_vm._s(item.want_start_time ? _vm.getFormateTime(item.want_start_time) : _vm.$t("projectManagement.unfinished"))+" ")])]),_c('div',[_c('i',{staticClass:"el-icon-time"}),_c('span',{},[_vm._v(" "+_vm._s(item.want_end_time ? _vm.getFormateTime(item.want_end_time) : _vm.$t("projectManagement.unfinished")))])])]),_c('div',{staticClass:"tag-box"},_vm._l((item.tags_name),function(tagItem,index){return _c('span',{key:index,style:({ backgroundColor: tagItem.value })},[_vm._v(" "+_vm._s(tagItem.name)+" ")])}),0),_c('div',{staticClass:"flex-center handel-box"},[(
                _vm.rightShow('CrmTask@activeProject') && item.permissions > 1
              )?_c('zc-icon-btn',{attrs:{"content":_vm.$t('projectManagement.activateProject'),"btn_type":"primary","btn_class":"form_button mr40","icon":"icon-jihuoxiangmu"},on:{"click":function($event){return _vm.activeProject(item.id)}}}):_vm._e(),(
                _vm.rightShow('CrmTask@deleteProject') && item.permissions > 1
              )?_c('zc-icon-btn',{attrs:{"content":_vm.$t('delete'),"btn_type":"danger","btn_class":"form_button","icon":"icon-qingkong"},on:{"click":function($event){return _vm.delProject(item.id)}}}):_vm._e()],1)])}):_c('p',{staticClass:"no-data center"},[_vm._v(" "+_vm._s(_vm.$t("projectManagement.noData"))+" ")])],2)]),_c('el-dialog',{directives:[{name:"move",rawName:"v-move"}],attrs:{"title":_vm.dialogTit,"visible":_vm.visible,"width":"500px"},on:{"update:visible":function($event){_vm.visible=$event}}},[_c('div',{staticClass:"flex-a-center mt20 flex-j-center"},[_c('span',{staticClass:"dia-span"},[_vm._v(" "+_vm._s(_vm.$t("projectManagement.invitationMemberType"))+"： ")]),_c('el-select',{staticClass:"w300",attrs:{"filterable":"","placeholder":_vm.$t('projectManagement.pleaseSelectMemberType'),"size":"mini"},model:{value:(_vm.invite_type),callback:function ($$v) {_vm.invite_type=$$v},expression:"invite_type"}},[_c('el-option',{attrs:{"label":_vm.$t('projectManagement.participant'),"value":"1"}}),_c('el-option',{attrs:{"label":_vm.$t('projectManagement.observer'),"value":"2"}})],1)],1),_c('div',{staticClass:"flex-a-center mt20 flex-j-center"},[_c('span',{staticClass:"dia-span"},[_vm._v(" "+_vm._s(_vm.$t("projectManagement.inviteMemeberList"))+"： ")]),_c('div',{staticClass:"w300"},[(_vm.userOptions && _vm.userOptions.length > 0)?_c('el-cascader',{staticClass:"w300",attrs:{"size":"small","options":_vm.userOptions,"show-all-levels":false,"props":{ multiple: true }},model:{value:(_vm.selectedArray),callback:function ($$v) {_vm.selectedArray=$$v},expression:"selectedArray"}}):_vm._e()],1)]),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"type":"danger","size":"mini"},on:{"click":function($event){_vm.visible = false}}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")]),_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$t("submit"))+" ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }