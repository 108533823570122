<template>
  <div id="project-management" v-cloak v-loading.fullscreen.lock="loading">
    <div class="header bg_white">
      <h1 class="titH">
        {{ $t("projectManagement.projectsList") }}
      </h1>
      <div class="flex">
        <div class="recycle-btn mr10" @click="jumpRecycle">
          <i class="iconfont icon-huishouzhan"></i>
          <span> {{ $t("projectManagement.recycle") }}</span>
        </div>
        <div
          class="add-btn"
          v-if="rightShow('CrmTask@projectInfo')"
          @click="addProject()"
        >
          {{ $t("projectManagement.createProject") }}
        </div>
      </div>
      <!-- <zc-icon-btn content="" btn_type="success"
            btn_class="form_button" icon="icon-xinzeng"
            v-if="rightShow('CrmTask@projectInfo')"
            @click="addProject()"></zc-icon-btn> -->
    </div>
    <div class="project-box">
      <h1 class="project-tit flex-between">
        <span>
          {{ $t("projectManagement.participateIn") }}
        </span>
        <span class="yellow-text toggle-span hand" @click="toggleProject(0)">
          <span>{{
            showProject[0]
              ? $t("projectManagement.hide")
              : $t("projectManagement.unfold")
          }}</span>
          <i
            :class="[
              showProject[0] ? 'el-icon-arrow-right' : 'el-icon-arrow-down',
            ]"
          ></i>
        </span>
      </h1>
      <div class="project-inner_box" v-show="showProject[0]">
        <template v-if="publicList.length">
          <div
            v-for="(item, index) in publicList"
            :key="index"
            class="project-item"
          >
            <div class="hand flex-a-center" @click="viewProject(item.id)">
              <img
                :src="
                  'api/Common/viewCrmTaskImg' + '?path=' + item.project_photo
                "
                class="project-img"
                v-if="item.project_photo !== ''"
              />
              <span class="project-icon" v-else>
                <i class="iconfont icon-xiangmu1"></i>
              </span>
              <div class="flex-between task-status-hand">
                <div class="flex-a-b">
                  <template
                    v-if="Date.parse(item.want_end_time) < new Date().getTime()"
                  >
                    <el-tooltip
                      class="item"
                      effect="dark"
                      :content="$t('projectManagement.overdue')"
                      placement="top-start"
                    >
                      <span
                        class="red-text project-name ellipsis"
                        :title="item.project_name"
                      >
                        {{ item.project_name }}
                      </span>
                    </el-tooltip>
                  </template>
                  <span
                    v-else
                    class="project-name ellipsis"
                    :title="item.project_name"
                  >
                    {{ item.project_name }}
                  </span>
                  <span :class="getStatusClass(item.status)">
                    -{{ formateStatus(item.status) }}
                  </span>
                </div>
                <div>
                  <el-popover placement="right" width="400" trigger="hover">
                    <el-cascader-panel
                      :options="item.userClass"
                      class="user-cascader"
                    >
                      <template slot-scope="{ node, data }">
                        <span>{{ data.label }}</span>
                        <span v-if="!node.isLeaf">
                          {{ data.children.length }}
                        </span>
                      </template>
                    </el-cascader-panel>
                    <div slot="reference">
                      <i class="iconfont icon-qunzu"></i>
                      <span class="project-num">{{ item.num_total }}</span>
                    </div>
                  </el-popover>
                </div>
              </div>
            </div>
            <div class="project-info multi-line" @click="viewProject(item.id)">
              {{ item.info }}
            </div>
            <div class="mb16 flex-between time-box">
              <div>
                <i class="el-icon-date"></i>
                <span>
                  {{
                    item.want_start_time
                      ? getFormateTime(item.want_end_time)
                      : $t("projectManagement.unfinished")
                  }}
                </span>
              </div>
              <div>
                <i class="el-icon-time"></i>
                <span class="">
                  {{
                    item.want_end_time
                      ? getFormateTime(item.want_end_time)
                      : $t("projectManagement.unfinished")
                  }}</span
                >
              </div>
            </div>
            <div class="task-total flex-between">
              <div>
                <span class="light-tit">
                  {{ $t("projectManagement.totalTasks") }}
                </span>
                <span
                  class="black-text"
                  style="cursor: pointer"
                  @click="go(item, 1)"
                  v-if="item.link_task_total_num > 0"
                >
                  {{ item.link_task_total_num }}
                </span>
                <span class="black-text" v-else>
                  {{ item.link_task_total_num }}
                </span>
              </div>
              <div>
                <span class="light-tit">
                  {{ $t("projectManagement.unfinished2") }}：
                </span>
                <span
                  class="blue-text"
                  style="cursor: pointer"
                  @click="go(item, 2)"
                  v-if="
                    item.link_task_total_num - item.link_task_completed_num > 0
                  "
                >
                  {{ item.link_task_total_num - item.link_task_completed_num }}
                </span>
                <span class="blue-text" v-else>
                  {{ item.link_task_total_num - item.link_task_completed_num }}
                </span>
              </div>
            </div>
            <div class="tag-box">
              <span
                v-for="(tagItem, index) in item.tags_name"
                :style="{ backgroundColor: tagItem.value }"
                :key="index"
              >
                {{ tagItem.name }}
              </span>
            </div>
            <div class="handel-box">
              <zc-icon-btn
                :content="$t('projectManagement.participationProjects')"
                btn_type="warning"
                btn_class="form_button"
                icon="icon-ruku"
                v-if="rightShow('CrmTask@joinProject')"
                @click="joinProject(item.id)"
              ></zc-icon-btn>
            </div>
            <!-- 进度 -->
            <div
              class="progress-box"
              v-if="item && item.link_task_total_num !== 0"
            >
              <!-- 黄色块 -->
              <template v-if="item.link_task_completed_num !== 0">
                <span
                  class="done-span"
                  v-for="(doneItem, index) in Math.round(
                    (item.link_task_completed_num / item.link_task_total_num) *
                      5
                  )"
                  :key="index"
                ></span>
              </template>
              <!-- 浅黄色块 -->
              <template
                v-if="
                  item.link_task_total_num - item.link_task_completed_num !== 0
                "
              >
                <span
                  class="undone-span"
                  v-for="(undoItem, index) in Math.round(
                    (item.link_task_total_num - item.link_task_completed_num) /
                      item.link_task_total_num
                  ) * 5"
                  :key="index"
                ></span>
              </template>
            </div>
          </div>
        </template>
        <p class="no-data center" v-else>
          {{ $t("projectManagement.noData") }}
        </p>
      </div>
    </div>
    <div class="project-box">
      <h1 class="project-tit flex-between">
        <span>
          {{ $t("projectManagement.unfinishedProjects") }}
        </span>
        <span class="yellow-text toggle-span hand" @click="toggleProject(1)">
          <span>{{
            showProject[1]
              ? $t("projectManagement.hide")
              : $t("projectManagement.unfold")
          }}</span>
          <i
            :class="[
              showProject[1] ? 'el-icon-arrow-right' : 'el-icon-arrow-down',
            ]"
          ></i>
        </span>
      </h1>
      <div class="project-inner_box" v-show="showProject[1]">
        <template v-if="myJoinList.length">
          <div
            v-for="(item, index) in myJoinList"
            :key="index"
            class="project-item"
          >
            <div class="hand flex-a-center" @click="viewProject(item.id)">
              <img
                :src="
                  'api/Common/viewCrmTaskImg' + '?path=' + item.project_photo
                "
                class="project-img"
                v-if="item.project_photo !== ''"
              />
              <span class="project-icon" v-else>
                <i class="iconfont icon-xiangmu1"></i>
              </span>
              <div class="flex-between task-status-hand">
                <div class="flex-a-b">
                  <template
                    v-if="Date.parse(item.want_end_time) < new Date().getTime()"
                  >
                    <el-tooltip
                      class="item"
                      effect="dark"
                      :content="$t('projectManagement.overdue')"
                      placement="top-start"
                    >
                      <span
                        class="red-text project-name ellipsis"
                        :title="item.project_name"
                      >
                        {{ item.project_name }}
                      </span>
                    </el-tooltip>
                  </template>
                  <span
                    v-else
                    class="project-name ellipsis"
                    :title="item.project_name"
                  >
                    {{ item.project_name }}
                  </span>
                  <span :class="getStatusClass(item.status)">
                    -{{ formateStatus(item.status) }}
                  </span>
                </div>
                <div>
                  <el-popover placement="right" width="400" trigger="hover">
                    <el-cascader-panel
                      class="user-cascader"
                      :options="item.userClass"
                    >
                      <template slot-scope="{ node, data }">
                        <span>{{ data.label }}</span>
                        <span v-if="!node.isLeaf">
                          {{ data.children.length }}
                        </span>
                      </template>
                    </el-cascader-panel>
                    <div slot="reference">
                      <i class="iconfont icon-qunzu"></i>
                      <span class="project-num">{{ item.num_total }}</span>
                    </div>
                  </el-popover>
                </div>
              </div>
            </div>
            <div
              class="project-info multi-line hand"
              @click="viewProject(item.id)"
            >
              {{ item.info }}
            </div>
            <div class="mb16 flex-between time-box">
              <div>
                <i class="el-icon-date"></i>
                <span>
                  {{
                    item.want_start_time
                      ? getFormateTime(item.want_start_time)
                      : $t("projectManagement.unfinished")
                  }}
                </span>
              </div>
              <div>
                <i class="el-icon-time"></i>
                <span class="">
                  {{
                    item.want_end_time
                      ? getFormateTime(item.want_end_time)
                      : $t("projectManagement.unfinished")
                  }}</span
                >
              </div>
            </div>
            <div class="task-total flex-between">
              <div>
                <span class="light-tit">
                  {{ $t("projectManagement.totalTasks") }}
                </span>
                <span
                  class="black-text"
                  style="cursor: pointer"
                  @click="go(item, 1)"
                  v-if="item.link_task_total_num > 0"
                >
                  {{ item.link_task_total_num }}
                </span>
                <span class="black-text" v-else>
                  {{ item.link_task_total_num }}
                </span>
              </div>
              <div>
                <span class="light-tit">
                  {{ $t("projectManagement.unfinished2") }}：
                </span>
                <span
                  class="blue-text"
                  style="cursor: pointer"
                  @click="go(item, 2)"
                  v-if="
                    item.link_task_total_num - item.link_task_completed_num > 0
                  "
                >
                  {{ item.link_task_total_num - item.link_task_completed_num }}
                </span>
                <span class="blue-text" v-else>
                  {{ item.link_task_total_num - item.link_task_completed_num }}
                </span>
              </div>
            </div>
            <div class="tag-box">
              <span
                v-for="(tagItem, index) in item.tags_name"
                :style="{ backgroundColor: tagItem.value }"
                :key="index"
              >
                {{ tagItem.name }}
              </span>
            </div>
            <div class="handel-box">
              <div
                class="btn-w btn-start"
                v-if="
                  rightShow('CrmTask@startProject') &&
                  item.status === 2 &&
                  item.permissions > 1
                "
              >
                <zc-icon-btn
                  :content="$t('projectManagement.startProject')"
                  btn_type="success"
                  btn_class="form_button mb10"
                  icon="icon-kaishixiangmu"
                  @click="startProject(item)"
                ></zc-icon-btn>
              </div>
              <div
                class="btn-w btn-finish"
                v-if="
                  rightShow('CrmTask@finishProject') &&
                  item.status == 3 &&
                  item.permissions > 1
                "
              >
                <zc-icon-btn
                  :content="$t('projectManagement.finishProject')"
                  btn_type="primary"
                  btn_class="form_button mb10"
                  icon="icon-wanchengxiangmu"
                  @click="finishProject(item.id)"
                ></zc-icon-btn>
              </div>
              <div
                class="btn-w btn-invite"
                v-if="
                  rightShow('CrmTask@inviteUserToProject') &&
                  item.checkInviteRole === true
                "
              >
                <zc-icon-btn
                  :content="$t('projectManagement.inviteMembers')"
                  btn_type="primary"
                  btn_class="form_button mb10 gray-btn"
                  icon="icon-xinzengkehu"
                  @click="inviteUserToProject(item.id)"
                ></zc-icon-btn>
              </div>
              <div class="btn-w btn-edit">
                <zc-icon-btn
                  :content="$t('edit')"
                  btn_type="warning"
                  btn_class="form_button gray-btn"
                  icon="icon-bianji"
                  v-if="
                    rightShow('CrmTask@projectInfoPut') && item.permissions > 1
                  "
                  @click="editProject(item.id)"
                ></zc-icon-btn>
              </div>
              <div class="btn-w btn-del">
                <zc-icon-btn
                  :content="$t('delete')"
                  btn_type="danger"
                  v-if="
                    rightShow('CrmTask@deleteProject') && item.permissions > 1
                  "
                  @click="delProject(item.id)"
                  btn_class="form_button"
                  icon="icon-qingkong"
                ></zc-icon-btn>
              </div>
            </div>
            <!-- 进度 -->
            <div
              class="progress-box"
              v-if="item && item.link_task_total_num !== 0"
            >
              <!-- 黄色块 -->
              <template v-if="item.link_task_completed_num !== 0">
                <span
                  class="done-span"
                  v-for="(doneItem, index) in Math.round(
                    (item.link_task_completed_num / item.link_task_total_num) *
                      5
                  )"
                  :key="index"
                ></span>
              </template>
              <!-- 浅黄色块 -->
              <template
                v-if="
                  item.link_task_total_num - item.link_task_completed_num !== 0
                "
              >
                <span
                  class="undone-span"
                  v-for="(undoItem, index) in Math.round(
                    (item.link_task_total_num - item.link_task_completed_num) /
                      item.link_task_total_num
                  ) * 5"
                  :key="index"
                ></span>
              </template>
            </div>
          </div>
        </template>
        <p class="no-data center" v-else>
          {{ $t("projectManagement.noData") }}
        </p>
      </div>
    </div>
    <div class="project-box">
      <h1 class="project-tit flex-between">
        <span>
          {{ $t("projectManagement.completedProjects") }}
        </span>
        <span class="yellow-text toggle-span hand" @click="toggleProject(2)">
          <span>{{
            showProject[2]
              ? $t("projectManagement.hide")
              : $t("projectManagement.unfold")
          }}</span>
          <i
            :class="[
              showProject[2] ? 'el-icon-arrow-right' : 'el-icon-arrow-down',
            ]"
          ></i>
        </span>
      </h1>
      <div class="project-inner_box" v-show="showProject[2]">
        <template v-if="finishedList.length">
          <div
            v-for="(item, index) in finishedList"
            :key="index"
            class="project-item"
          >
            <div class="hand flex-a-center" @click="viewProject(item.id)">
              <img
                :src="
                  'api/Common/viewCrmTaskImg' + '?path=' + item.project_photo
                "
                class="project-img"
                v-if="item.project_photo !== ''"
              />
              <span class="project-icon" v-else>
                <i class="iconfont icon-xiangmu1"></i>
              </span>
              <div class="flex-between task-status-hand">
                <div class="flex-a-b">
                  <span
                    class="project-name ellipsis"
                    :title="item.project_name"
                  >
                    {{ item.project_name }}
                  </span>
                  <span :class="getStatusClass(item.status)">
                    -{{ formateStatus(item.status) }}
                  </span>
                </div>
                <div>
                  <el-popover placement="right" width="400" trigger="hover">
                    <el-cascader-panel
                      class="user-cascader"
                      :options="item.userClass"
                    >
                      <template slot-scope="{ node, data }">
                        <span>{{ data.label }}</span>
                        <span v-if="!node.isLeaf">
                          {{ data.children.length }}
                        </span>
                      </template>
                    </el-cascader-panel>
                    <div slot="reference">
                      <i class="iconfont icon-qunzu"></i>
                      <span class="project-num">{{ item.num_total }}</span>
                    </div>
                  </el-popover>
                </div>
              </div>
            </div>
            <div
              class="project-info multi-line hand"
              @click="viewProject(item.id)"
            >
              {{ item.info }}
            </div>
            <div class="mb16 flex-between time-box">
              <div>
                <i class="el-icon-date"></i>
                <span>
                  {{
                    item.want_start_time
                      ? getFormateTime(item.want_start_time)
                      : $t("projectManagement.unfinished")
                  }}
                </span>
              </div>
              <div>
                <i class="el-icon-time"></i>
                <span class="">
                  {{
                    item.want_end_time
                      ? getFormateTime(item.want_end_time)
                      : $t("projectManagement.unfinished")
                  }}</span
                >
              </div>
            </div>
            <div class="tag-box">
              <span
                v-for="(tagItem, index) in item.tags_name"
                :style="{ backgroundColor: tagItem.value }"
                :key="index"
              >
                {{ tagItem.name }}
              </span>
            </div>
            <div class="flex-center handel-box">
              <zc-icon-btn
                :content="$t('projectManagement.activateProject')"
                btn_type="primary"
                btn_class="form_button mr40"
                icon="icon-jihuoxiangmu"
                v-if="
                  rightShow('CrmTask@activeProject') && item.permissions > 1
                "
                @click="activeProject(item.id)"
              ></zc-icon-btn>
              <zc-icon-btn
                :content="$t('delete')"
                btn_type="danger"
                btn_class="form_button"
                icon="icon-qingkong"
                @click="delProject(item.id)"
                v-if="
                  rightShow('CrmTask@deleteProject') && item.permissions > 1
                "
              ></zc-icon-btn>
            </div>
          </div>
        </template>
        <p class="no-data center" v-else>
          {{ $t("projectManagement.noData") }}
        </p>
      </div>
    </div>
    <el-dialog :title="dialogTit" :visible.sync="visible" width="500px" v-move>
      <div class="flex-a-center mt20 flex-j-center">
        <span class="dia-span">
          {{ $t("projectManagement.invitationMemberType") }}：
        </span>
        <el-select
          class="w300"
          v-model="invite_type"
          filterable
          :placeholder="$t('projectManagement.pleaseSelectMemberType')"
          size="mini"
        >
          <el-option
            :label="$t('projectManagement.participant')"
            value="1"
          ></el-option>
          <el-option
            :label="$t('projectManagement.observer')"
            value="2"
          ></el-option>
        </el-select>
      </div>
      <div class="flex-a-center mt20 flex-j-center">
        <span class="dia-span">
          {{ $t("projectManagement.inviteMemeberList") }}：
        </span>
        <div class="w300">
          <el-cascader
            class="w300"
            size="small"
            v-model="selectedArray"
            :options="userOptions"
            :show-all-levels="false"
            :props="{ multiple: true }"
            v-if="userOptions && userOptions.length > 0"
          >
          </el-cascader>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="danger" @click="visible = false" size="mini">
          {{ $t("cancel") }}
        </el-button>
        <el-button type="primary" @click="submit" size="mini">
          {{ $t("submit") }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import "@/plugins/move";
import { getTabParam, setTabParam } from "@/utils/common";
import { toMessage } from "@/utils/message";
import ZcIconBtn from "@/components/ZcIconBtn.vue";

export default {
  name: "ProjectManagement",
  components: {
    ZcIconBtn,
  },
  data() {
    return {
      loading: true,
      visible: false,
      invite_type: "",
      invite_user_ids: "",
      selectedArray: [],
      publicList: [],
      myJoinList: [],
      finishedList: [],
      userOptions: [],
      selectedAll: false,
      projectName: "",
      info: "",
      user: "",
      desc: "",
      id: "",
      dialogTit: "",
      startTime: "",
      endTime: "",
      isDisable: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        },
      },
      showProject: [true, true, true],
    };
  },
  inject: ["rightShow"],
  methods: {
    addTab: function (
      option = "Index",
      action = "welCome",
      name = "首页",
      param
    ) {
      let url = "/" + option + "/" + action;
      if (param) {
        for (var i in param) {
          url += "/" + param[i];
        }
      }
      console.log(name);
      this.$router.push(url);
    },
    // 格式化状态：显示状态名称
    formateStatus: function (value) {
      let taskStatus = {
        1: "projectManagement.unfinished",
        2: "projectManagement.notStarted",
        3: "projectManagement.working",
        4: "projectManagement.finished",
      };
      return this.$t(taskStatus[value]);
    },
    go: function (item, type) {
      this.addTab("CrmTask", "taskManagement", "layout.taskManagement", {
        name: item.project_name,
        type: type,
      });
    },
    // 展开收起
    toggleProject: function (index) {
      if (this.showProject[index]) {
        this.showProject.splice(index, 1, false);
      } else {
        this.showProject.splice(index, 1, true);
      }
    },
    // 状态的class
    getStatusClass: function (value) {
      let statusClass = {
        1: "gray-text",
        2: "yellow-text",
        3: "blue-text",
        4: "black-text",
      };
      return statusClass[value];
    },
    getFormateTime: function (time) {
      if (time) {
        return time.substring(16, 0);
      } else {
        return "";
      }
    },
    // 获取列表
    getInfo: function () {
      this.loading = true;
      this.axios
        .post("/home/crmTask/getProjectList")
        .then((res) => {
          this.loading = false;
          if (res.data.data.publicList && res.data.data.publicList.length > 0) {
            this.publicList = res.data.data.publicList;
            this.showProject.splice(0, 1, true);
          } else {
            this.showProject.splice(0, 1, false);
          }
          if (res.data.data.myJoinList && res.data.data.myJoinList.length > 0) {
            this.myJoinList = res.data.data.myJoinList;
          }
          if (
            res.data.data.finishedList &&
            res.data.data.finishedList.length > 0
          ) {
            this.finishedList = res.data.data.finishedList;
            this.showProject.splice(2, 1, true);
          } else {
            this.showProject.splice(2, 1, false);
          }
        })
        .catch(() => {});
    },
    // 参加项目
    joinProject: function (id) {
      this.$confirm(
        this.$t("projectManagement.determineToParticipate"),
        this.$t("tips"),
        {
          confirmButtonText: this.$t("confirm"),
          cancelButtonText: this.$t("cancel"),
          type: "warning",
        }
      ).then(() => {
        this.loading = false;
        this.axios
          .post("/home/crmTask/joinProject", {
            id: id,
          })
          .then((res) => {
            this.loading = false;
            if (res.data.status !== 1) {
              this.$message(toMessage("error", res.data.msg));
              return;
            }
            this.$message(toMessage());
            this.getInfo();
          })
          .catch(() => {});
      });
    },
    // 完成项目
    finishProject: function (id) {
      this.$confirm(
        this.$t("projectManagement.determineCompletedProject"),
        this.$t("tips"),
        {
          confirmButtonText: this.$t("confirm"),
          cancelButtonText: this.$t("cancel"),
          type: "success",
        }
      ).then(() => {
        this.loading = false;
        this.axios
          .post("/home/crmTask/finishProject", {
            id: id,
          })
          .then((res) => {
            this.loading = false;
            if (res.data.status !== 1) {
              this.$message(toMessage("error", res.data.msg));
              return;
            }
            this.$message(toMessage());
            this.getInfo();
          })
          .catch(() => {});
      });
    },
    // 激活项目
    activeProject: function (id) {
      this.$confirm(
        this.$t("projectManagement.determineActiveProject"),
        this.$t("tips"),
        {
          confirmButtonText: this.$t("confirm"),
          cancelButtonText: this.$t("cancel"),
          type: "success",
        }
      ).then(() => {
        this.loading = false;
        this.axios
          .post("/home/crmTask/activeProject", {
            id: id,
          })
          .then((res) => {
            this.loading = false;
            if (res.data.status !== 1) {
              this.$message(toMessage("error", res.data.msg));
              return;
            }
            this.$message(toMessage());
            this.getInfo();
          })
          .catch(() => {});
      });
    },
    // 开始项目
    startProject: function (item) {
      this.$confirm(
        this.$t("projectManagement.confirmStartProject"),
        this.$t("tips"),
        {
          confirmButtonText: this.$t("confirm"),
          cancelButtonText: this.$t("cancel"),
          type: "success",
        }
      ).then(() => {
        if (item && item.charge_user_ids == "") {
          this.$message(
            toMessage("error", this.$t("projectManagement.projectNoLeader"))
          );
          return;
        }
        this.loading = false;
        this.axios
          .post("/home/crmTask/startProject", {
            id: item.id,
          })
          .then((res) => {
            this.loading = false;
            if (res.data.status !== 1) {
              this.$message(toMessage("error", res.data.msg));
              return;
            }
            this.$message(toMessage());
            this.getInfo();
          })
          .catch(() => {});
      });
    },
    // 邀请成员
    inviteUserToProject: function (id) {
      this.selectedArray = [];
      this.userOptions = [];
      this.invite_user_ids = "";
      this.invite_type = "";
      this.dialogTit = this.$t("projectManagement.inviteMembers");
      this.visible = true;
      this.id = id;
      this.axios
        .post("/home/crmTask/getOtherUserList", {
          id: id,
        })
        .then((res) => {
          if (res.data.status !== 1) {
            this.$message(toMessage("error", res.data.msg));
            return;
          }
          this.userOptions = res.data.data.userGroup;
        })
        .catch(() => {});
    },
    getUserIdArray: function (userGroup) {
      let userArray = [];
      if (userGroup && userGroup.length > 0) {
        userGroup.forEach((item) => {
          userArray.push(item[1]);
        });
      }
      return userArray;
    },
    // 详情页
    viewProject: function (id) {
      if (this.rightShow("CrmTask@projectInfo")) {
        this.addTab(
          "CrmTask",
          "projectInfo",
          this.$t("projectManagement.project") +
            "-" +
            this.$t("projectManagement.details"),
          { id: id, is_view: 1 }
        );
      }
    },
    // 编辑页
    editProject: function (id) {
      if (this.rightShow("CrmTask@projectInfo")) {
        this.addTab(
          "CrmTask",
          "projectInfo",
          this.$t("projectManagement.project") + "-" + this.$t("edit"),
          { id: id }
        );
      }
    },
    // 新增项目
    addProject: function () {
      this.addTab(
        "CrmTask",
        "projectInfo",
        this.$t("projectManagement.project") +
          "-" +
          this.$t("projectManagement.add")
      );
    },
    submit: function () {
      if (this.selectedArray.length > 0) {
        let selectedUser = this.getUserIdArray(this.selectedArray);
        this.invite_user_ids = selectedUser.join(",");
      }

      if (this.invite_type == "") {
        this.$message(
          toMessage(
            "error",
            this.$t("projectManagement.pleaseSelectMemberType")
          )
        );
        return;
      }
      if (this.invite_user_ids == "") {
        this.$message(
          toMessage("error", this.$t("projectManagement.selectMembers"))
        );
        return;
      }
      if (this.id == "") {
        this.$message(
          toMessage("error", this.$t("projectManagement.selectProject"))
        );
        return;
      }

      this.loading = false;
      let data = {
        id: this.id,
        invite_type: this.invite_type,
        invite_user_ids: this.invite_user_ids,
      };

      this.axios
        .post("/home/crmTask/inviteUserToProject", data)
        .then((res) => {
          this.loading = false;
          if (res.data.status !== 1) {
            this.$message(toMessage("error", res.data.msg));
            return;
          }
          this.$message(toMessage());
          this.getInfo();
          this.visible = false;
        })
        .catch(() => {});
    },
    delProject: function (id) {
      this.$confirm(
        this.$t("projectManagement.confirmDelete"),
        this.$t("tips"),
        {
          confirmButtonText: this.$t("confirm"),
          cancelButtonText: this.$t("cancel"),
          type: "warning",
        }
      ).then(() => {
        this.axios
          .post("/home/crmTask/deleteProject", {
            id: id,
          })
          .then((res) => {
            this.loading = false;
            if (res.data.status !== 1) {
              this.$message(toMessage("error", res.data.msg));
              return;
            }
            this.$message(toMessage());
            this.getInfo();
          })
          .catch(() => {});
      });
    },
    jumpRecycle: function () {
      this.addTab(
        "CrmTask",
        "projectRecycle",
        this.$t("projectManagement.project") +
          "-" +
          this.$t("projectManagement.recycle")
      );
    },
    showTab: function () {
      let tabData = getTabParam();
      if (tabData.isTab) {
        this.getInfo();
      }
      tabData.isTab = false;
      setTabParam(tabData);
    },
  },
  created: function () {
    this.getInfo();
  },
};
</script>

<style scoped>
.handel-box {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 44px;
  padding-left: 22px;
  padding-right: 22px;
  display: flex;
  flex-wrap: wrap;
  height: 66px;
}
.handel-box >>> .el-button {
  min-width: 80px;
}
.btn-w {
  position: absolute;
}
.btn-w div {
  margin-right: 0;
  margin-left: 0;
}
.btn-w:nth-child(2n) {
  display: flex;
  justify-content: flex-end;
}
.btn-start,
.btn-finish {
  left: 45px;
  top: 0;
}
.btn-invite {
  right: 45px;
  top: 0;
}
.btn-edit {
  left: 45px;
  bottom: 0;
}
.btn-del {
  right: 45px;
  bottom: 0;
}
.handel-box >>> .el-button--primary {
  color: #409eff;
  border-color: #409eff;
  background-color: #fff;
}
.handel-box >>> .gray-btn {
  color: #666666;
  border-color: #666666;
  background: #fff;
}
.handel-box >>> .el-button--warning {
  color: #e6a23c;
  border-color: #e6a23c;
  background: #fff;
}
.handel-box >>> .el-button--success {
  color: #67c23a;
  border-color: #67c23a;
  background: #fff;
}
.handel-box >>> .el-button--danger {
  color: #f56c6c;
  border-color: #f56c6c;
  background: #fff;
}
.header {
  height: 89px;
  padding: 28px 32px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
}
.bg_white {
  background: #fff;
}
.recycle-btn {
  padding: 5px 16px;
  border-radius: 3px;
  text-align: center;
  font-size: 14px;
  border: 1px solid rgba(255, 87, 92, 1);
  color: #ff575c;
  cursor: pointer;
}
.titH {
  font-size: 24px;
  font-weight: 600;
}
.add-btn {
  padding: 6px 10px;
  font-size: 14px;
  color: #000000;
  line-height: 1.5;
  cursor: pointer;
}
.dia-span {
  display: inline-block;
  width: 100px;
  text-align: right;
}
#project-management {
  background: #eaeaea;
  padding: 6px !important;
  overflow: auto;
}
.grid-content {
  border-radius: 4px;
  height: 126px;
  min-width: 800px;
}
.w220 {
  width: 220px;
}
.project-box {
  padding: 24px 32px;
  padding-bottom: 8px;
  background: #fff;
  margin-bottom: 16px;
}
.project-tit {
  font-size: 18px;
  color: #1c1c1e;
  margin-bottom: 16px;
  font-weight: 600;
}
.project-inner_box {
  display: flex;
  flex-wrap: wrap;
  min-height: 409px;
  position: relative;
}
.project-item {
  width: 310px;
  height: 450px;
  padding: 22px;
  border: 1px solid rgba(225, 225, 225, 1);
  margin-right: 24px;
  margin-bottom: 24px;
  font-size: 14px;
  position: relative;
}
.project-item:last-child {
  margin-right: 0;
}
.project-img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.project-name {
  font-size: 16px;
  font-weight: 600;
  margin: 0 8px;
  display: inline-block;
  max-width: 112px;
  margin-right: 0;
}
.project-info {
  font-size: 14px;
  color: #4a4a4a;
  line-height: 1.5;
  margin-top: 13px;
  margin-bottom: 25px;
  height: 105px;
}
.multi-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}
.mb16 {
  margin-bottom: 16px;
}
.project-num {
  color: #ff7315;
  font-size: 14px;
  margin-left: 3px;
}
.light-tit {
  opacity: 0.6;
}
.w300 {
  width: 300px;
}
.mr40 {
  margin-right: 40px;
}
.project-icon {
  color: #fff;
  background: #ff7315;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: inline-block;
  line-height: 32px;
  text-align: center;
}
.progress-box {
  position: absolute;
  bottom: 16px;
  left: 0;
  width: 100%;
  padding-left: 23px;
  padding-right: 23px;
  display: flex;
  align-items: center;
}
.progress-box span {
  width: 46px;
  height: 7px;
  margin-right: 8px;
}
.progress-box span:last-child {
  margin-right: 0;
}
.done-span {
  background: rgba(255, 115, 21, 1);
}
.undone-span {
  background: rgba(255, 115, 21, 0.1);
}
.no-data {
  font-size: 14px;
  color: #666;
}
.toggle-span {
  font-size: 12px;
}
.time-box {
  display: flex;
  flex-direction: column;
}
.time-box div {
  margin-bottom: 10px;
}
.time-box i {
  color: #a6a6a6;
}
.time-box span {
  color: #000000;
}
.task-total div {
  width: 50%;
}
.task-status-hand {
  width: calc(100% - 32px);
}
/* 查看人员面板 */
.user-cascader,
.user-cascader >>> .el-cascader-menu {
  border: none !important;
  border-right: none;
}
.user-cascader >>> .el-cascader-node.is-active {
  color: #606266;
  font-weight: 400;
}
.user-cascader >>> .el-cascader-node__prefix {
  display: none;
}
.tag-box {
  height: 26px;
  overflow: hidden;
  margin-top: 22px;
}
.tag-box span {
  padding: 3px 14px;
  color: #fff;
  font-size: 14px;
  border-radius: 3px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  margin-right: 12px;
}
.tag-box span:last-child {
  margin-right: 0;
}
</style>